<template>
  <v-container v-if="event && event.id > 0">
    <v-row>
      <v-col lg="12">
        <h1 class="pa-2">{{ event.name }}</h1>
        <v-btn small color="primary" outlined dark rounded  :to="{name: 'events.item.info', params: {id: $store.state.events.currentEvent.id}}">
          Назад на страницу мероприятия
        </v-btn>
      </v-col>
      <v-col lg="12" style="min-height: 400px">
        <v-card outlined min-height="100%">

          <v-tabs background-color="transparent"
                  color="primary"
                  grow
                  v-model="part"
          >
            <v-tab :to="{name: 'event.edit', params: {id: $store.state.events.currentEvent.id}}"
                   v-if="$store.state.events.currentEvent.can_admin">
              Редактировать
            </v-tab>
            <v-tab :to="{name: 'event.participants', params: {id: $store.state.events.currentEvent.id}}"
                   v-if="$store.state.events.currentEvent.can_admin">
              Участники
            </v-tab>
            <v-tab :to="{name: 'event.criteria', params: {id: $store.state.events.currentEvent.id}}"
                   v-if="$store.state.events.currentEvent.can_admin">
              Критерии
            </v-tab>
          </v-tabs>
          <router-view></router-view>
        </v-card>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import events from "@/api/events";
// import EventQRRegisterComponent from "@/components/OldEvents/EventQRRegisterComponent";


export default {
  name: "EventItemOldComponent",
  mounted() {
    this.$store.dispatch('getEvent', {id: this.$route.params.id}).then(() => {
      this.$emit('changeTitle', this.event.name)
      if(!this.$store.state.events.currentEvent.can_admin){
        this.$router.back();
      }
      this.$store.dispatch('getEventParticipants', {id: this.$store.state.events.currentEvent.id});
    });

  },
  computed: {
    event() {
      return this.$store.state.events.currentEvent;
    }
  },
  data() {
    return {
      part: 0,
      connectToEvent: false
    }
  },

  methods: {
    connect() {
      this.connectToEvent = true;
      events.link({id: this.event.id}).then((response) => {
        window.location.href = response.data;
      })
    }
  }
}
</script>

<style scoped>

</style>
